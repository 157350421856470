import { useContext, useEffect, useState, type ReactElement } from 'react'
import classnames from 'classnames'

import Header from '@/components/common/Header'
import css from './styles.module.css'
import SafeLoadingError from '../SafeLoadingError'
import Footer from '../Footer'
import SideDrawer from './SideDrawer'
import { useIsSidebarRoute } from '@/hooks/useIsSidebarRoute'
import { TxModalContext } from '@/components/tx-flow'
import BatchSidebar from '@/components/batch/BatchSidebar'
import Close from '@mui/icons-material/Close'
import { IconButton } from '@mui/material'

const PageLayout = ({ pathname, children }: { pathname: string; children: ReactElement }): ReactElement => {
  const [isSidebarRoute, isAnimated] = useIsSidebarRoute(pathname)
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(true)
  const [isBatchOpen, setBatchOpen] = useState<boolean>(false)
  const { setFullWidth } = useContext(TxModalContext)

  const hideGlobalBannerSessionStorageKey: string = 'hideGlobalBanner'
  const [isBannerVisible, setBannerVisible] = useState<boolean>(false)

  const updateHeaderHeight = (isVisible: boolean) => {
    if (typeof window !== 'undefined') {
      const root = document.documentElement
      if (isVisible) {
        const originalHeaderHeight =
          parseFloat(getComputedStyle(root).getPropertyValue('--original-header-height')) || 0
        const globalBannerHeight = parseFloat(getComputedStyle(root).getPropertyValue('--global-banner-height')) || 0
        root.style.setProperty('--header-height', `${originalHeaderHeight + globalBannerHeight}px`)
      } else {
        root.style.setProperty('--header-height', getComputedStyle(root).getPropertyValue('--original-header-height'))
      }
    }
  }

  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_GLOBAL_BANNER_HTML &&
      sessionStorage.getItem(hideGlobalBannerSessionStorageKey) !== 'true'
    ) {
      setBannerVisible(true)
      updateHeaderHeight(true)
    }

    return () => updateHeaderHeight(false)
  }, [])

  useEffect(() => {
    updateHeaderHeight(isBannerVisible)
  }, [isBannerVisible])

  useEffect(() => {
    setFullWidth(!isSidebarOpen)
  }, [isSidebarOpen, setFullWidth])

  return (
    <>
      <header className={css.header}>
        {/* Conditionally render the banner */}
        {isBannerVisible && process.env.NEXT_PUBLIC_GLOBAL_BANNER_HTML && (
          <div id="global-banner">
            <div id="banner-content" dangerouslySetInnerHTML={{ __html: process.env.NEXT_PUBLIC_GLOBAL_BANNER_HTML }} />
            <IconButton
              id="banner-close-button"
              onClick={() => {
                setBannerVisible(false)
                sessionStorage.setItem(hideGlobalBannerSessionStorageKey, 'true')
                updateHeaderHeight(false)
              }}
              size="small"
            >
              <Close fontSize="small" />
            </IconButton>
          </div>
        )}
        <Header onMenuToggle={isSidebarRoute ? setSidebarOpen : undefined} onBatchToggle={setBatchOpen} />
      </header>

      {isSidebarRoute && <SideDrawer isOpen={isSidebarOpen} onToggle={setSidebarOpen} />}

      <div
        className={classnames(css.main, {
          [css.mainNoSidebar]: !isSidebarOpen || !isSidebarRoute,
          [css.mainAnimated]: isSidebarRoute && isAnimated,
        })}
      >
        <div className={css.content}>
          <SafeLoadingError>{children}</SafeLoadingError>
        </div>

        <BatchSidebar isOpen={isBatchOpen} onToggle={setBatchOpen} />

        <Footer />
      </div>
    </>
  )
}

export default PageLayout
